@font-face {
  font-family: "bakhBold";
  src: local("bakhBold"),
    url(./fonts/Yekan\ Bakh\ FA\ 06\ Bold.ttf) format("truetype");
}
@font-face {
  font-family: "rokhBlack";
  src: local("rokhBlack"), url(./fonts/Rokh-Black.woff) format("truetype");
}
@font-face {
  font-family: "rokhBold";
  src: local("rokhBold"), url(./fonts/Rokh-Bold.woff) format("truetype");
}
@font-face {
  font-family: "rokhFNBold";
  src: local("rokhFNBold"), url(./fonts/RokhFaNum-Bold.woff) format("truetype");
}
@font-face {
  font-family: "bakhRegular";
  src: local("bakhRegular"),
    url(./fonts/Yekan\ Bakh\ FA\ 04\ Regular.ttf) format("truetype");
}
@font-face {
  font-family: "rokhFNMedium";
  src: local("rokhFNMedium"),
    url(./fonts/RokhFaNum-Medium.woff) format("truetype");
}
@font-face {
  font-family: "bakhHeavy";
  src: local("bakhHeavy"),
    url(./fonts/Yekan\ Bakh\ FA\ 07\ Heavy.ttf) format("truetype");
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
:root {
  --mainColor: white;
  --textColor: rgb(235, 235, 235);
  --backgroundColor: #161920;
}
.bg-bg {
  background-color: var(--backgroundColor);
}
.round {
  border-radius: 20px;
}
.border-main {
  border-color: var(--mainColor) !important;
}
.bg-main {
  background-color: var(--mainColor);
}
.text-main {
  color: var(--mainColor);
}
.font-black {
  font-family: rokhBlack;
}
.menu-name {
  font-size: 25px;
}
.bell-wrapper {
  border: 2px solid #4747478a;
  cursor: pointer;
}
.cursor-pointer {
  cursor: pointer;
}
html, .tt {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
html::-webkit-scrollbar, .tt::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

body {
  background-color: var(--backgroundColor);
  font-family: rokhFNMedium;
  color: white;
}



img {
  object-fit: cover;
  max-width: 100%;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.row {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.row::-webkit-scrollbar {
  display: none;
}
button:active,
button:focus,
a:active,
a:focus {
  outline: none;
  -moz-outline-style: none;
}
.item {
  cursor: pointer;
}

.food-card--price {
  font-family: rokhFNBold;
}
.food-card--title,
.item-title {
  font-family: rokhFNBold;
}
.bell-unread-lable {
  font-size: 40px;
  top: 0;
  right: -4px;
}
.categories-wrapper {
  background-color: var(--backgroundColor);
  padding: 15px 11px;
}
.left-slide-animation {
  animation: right-slide 1.9s cubic-bezier(0.61, 1, 0.88, 1) 0s 5 normal none;
}
@keyframes right-slide {
  0% {
    transform: translateX(-40px);
  }

  100% {
    transform: translateX(0);
  }
}

.categories-item {
  border: 2px solid #4747478a;
  border-radius: 30px;
  font-family: rokhBold;
  column-gap: 20px;
  margin-left: 15px;
  cursor: pointer;
  width: 113px;
  min-height: 120px;

}
.categories-item:last-child {
  margin-left: 0;
}
.categories-item--active {
  background-color: var(--mainColor);
  transition: 0.1s;
}
.search-wrapper {
  border: 2px solid #4747478a;
  border-radius: 30px;
}
.search-input {
  width: 100%;
  border: none;
  background-color: transparent;
  padding: 1.2em;
  color: white;
  border-radius: 30px;
  outline: none;
}
.card-meta-wrapper {
  background-color: rgba(46, 46, 46, 0.863);  
  width: 90%;
  margin: 0 auto;
  border-radius: 20px;
  height: 100px;
  padding: 15px;
}

.nav-wrapper {
  background-color: #161920af;
  backdrop-filter: blur(20px);
  z-index: 9;
  height: 85px;
  box-shadow: 0px -30px 100px #161920ce;
  border-radius: 30px;
  bottom: 20px;
  left: 20px;
  right: 20px;
  border: 1px solid rgba(100, 100, 100, 0.192);
}
.nav-space {
  margin-top: 100px;
}
.nav-cart-btn-wrapper {
  width: 70px;
  height: 70px;
  text-align: center;
  border-radius: 50%;
}
.hi {
  animation: t 1s ease 0s 2 normal forwards;
}
@keyframes t {
  0% {
    opacity: 0.5;
    transform: translateX(-50%);
  }
  80% {
    opacity: 1;
    transform: translateX(-0%);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%);
  }
}

.nav-btn {
  color: white;
  font-size: 20px;
  display: inline-block;
  height: 20px;
}

.food-card {
  height: 320px;
  cursor: pointer;
  border-radius: 30px;
  background-position: center top !important;
  background-size: cover !important;
}
/* cart */
.cart-item-wrapper {
  background-color: #1d212b;
  border-radius: 20px;
}

.cart-item-img {
  min-width: 100px;
  min-height: 100px;
}
.bg-second {
  background-color: #1d212b;
}
.cart-total-wrapper {
  background-color: #1d212bb9;
  backdrop-filter: blur(10px);
  border-radius: 30px 30px 0 0;
  box-shadow: 0px -30px 100px #161920ce;
  height: 200px;
}
.cart-total-wrapper-space {
  margin-top: 230px;
}
.cart-plus {
  font-size: 22px;
  cursor: pointer;
  height: 24px;
  display: inline-block;
  border-radius: 50%;
}

/* item detail */
.item-detail {
  animation: myAnim 500ms ease 0s 1 normal forwards;
}
@keyframes myAnim {
  0% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.add-to-cart-wrapper-space {
  margin-top: 100px;
}
.add-to-cart-wrapper {
  background-color: var(--backgroundColor);
  box-shadow: 0px -30px 100px #161920;
}
.cartadded {
  animation: cartadded 1.2s ease 0s 1 normal forwards;
}
@keyframes cartadded {
  0% {
    opacity: 0;
    transform: translateY(300px) rotate(250deg);
  }

  100% {
    opacity: 1;
    transform: translateY(0) rotate(0deg);
  }
}
.add-to-cart {
  border: none;
  padding: 15px;
  font-family: rokhBold;
  width: 65%;
}
.item-detail .price {
  font-size: 30px;
  font-family: rokhFNBold;
  height: 34px;
}
.item-detail .category {
  border-radius: 10px;

  padding: 5px 10px;
  text-align: center;
  font-size: 14px;
}

.ingradients {
  column-gap: 10px;
}
.ingradients-item {
  border: 1px solid #4747478a;
  border-radius: 15px;
  cursor: pointer;
}

.item-detail .content-wrapper {
  width: 100%;
  border-radius: 30px 30px 0 0;
  margin-top: -30px;
  box-shadow: 0px -30px 100px #161920ce;
  position: relative;
}
.item-detail .image {
  scroll-snap-align: start;
}
.item-detail .back-wrapper {
  left: 10px;
  top: 10px;
  background-color: #16192088;
  color: white;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  text-align: center;
}
.item-detail .back {
  font-size: 20px;
  height: 20px;
}
.item-detail .item-title {
  width: 70%;
}

.item-detail .quantity-wrapper {
  padding: 20px;
  border-radius: 20px;
  width: 30%;
}
.item-detail .quantity-wrapper .bi-plus,
.item-detail .quantity-wrapper .bi-dash {
  font-size: 20px;
  height: 20px;
}
.item-detail .quantity-wrapper .quantity {
  font-size: 18px;
  height: 18px;
  font-family: rokhFNBold;
}

.cart-count {
  position: absolute;
  right: 9px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  font-family: rokhFNBold;
  top: 9px;
  font-size: 18px;
}

.added-item-shadow {
  width: 100%;
  background-color: rgba(10, 10, 10, 0.575);
}

.options-wrapper {
  z-index: 10;
  border-radius: 30px 35px 0 0;
  height: 58vh;
  
  overflow-y: hidden;
  animation: options 200ms ease 0s 1 normal forwards;
}

.tt {
  height: 100%;
  overflow-y: scroll;

}

.options-wrapper-back {
  background-color: #161920f1;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 100000;
}
@keyframes options {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.option-item-wrapper {
  border: 1px solid transparent;
  cursor: pointer;
}
.option-item-wrapper:last-child{
  margin-bottom: 3rem !important;
}

.option-item-checkbox {
  width: 24px;
  height: 24px;
  cursor: pointer;
  accent-color: var(--mainColor);
  outline: none;
}

.cart-option-wrapper:last-child {
  border-radius: 0 0 20px 20px;
}

.options-add-wrapper {
  position: sticky;
  bottom: 0;
}
.cart-item-type-wrapper:first-child {
  margin-top: 15px;
}
.cart-item-type-wrapper {
  margin-top: 5px;
}

.google-map iframe {
  border-radius: 20px;
  border: none;
  width: 100%;
  height: 350px;
}


.info-carousel-next:hover, .info-carousel-prev:hover {
  background-color: var(--mainColor);
  transition: .2s;
}

@media (min-width: 768px) {
  .item-detail {
    max-width: 768px;
    margin: 0 auto;

  }
}

.body-no-scroll {
  height: 100vh;
  width: 100vw;
  touch-action: none;
  -webkit-overflow-scrolling: none;
  overflow: hidden;
  overscroll-behavior: none;
}

.options-item-title {
  position: absolute;
  top: 25%;
  left: 50%;
  transform:translate(-50%, -50%)
}

.info-head {
  background: url('./images/infoBackground.jpg');
  background-position: center center;
  background-size: cover;
  height: 350px;
  position: relative;
}

.info-head-layer {
  background-color: #16192056;
  backdrop-filter: blur(4px);

  position: absolute;
  width: 100%;
  height: 100%;
}

.card-options-badge {
  background-color: rgba(46, 46, 46, 0.863);  

}

/* styles.css or your custom CSS file */
.splash-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: 1s;
  z-index: -1;
}
.show {
  opacity: 1;
  z-index: 9999;

}


@media (max-width: 370px) {
  .food-card-col {
    width: 93% !important;
  }
  .food-card {
    height: 270px !important;
  }
  .categories-item {
    min-height: none;
  }
}
